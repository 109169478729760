import React from "react"
import { Header, Icon, Breadcrumb, List, Label, Table } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ReferencesOverviewPage = (args) => {
  const misconceptions = args.data.misconceptions.nodes;
  const bgcolor = (i) => {
    return "hsl(0, 0%, "+ (100- (i + 1) % 2 * 5) + "%)";
  };
  return <Layout>
    <SEO title="References Overview" />
    <div style={{ marginLeft: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link as={Link} to="/overview">
          Overview
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>References</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          References
          <Header.Subheader>
            {misconceptions.length} Misconceptions, {' '}
            {misconceptions.filter(mc => mc.frontmatter.references).length} with References
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Misconception</Table.HeaderCell>
            <Table.HeaderCell>Language</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>References</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {misconceptions.map((misconception, mi) => (
            <Table.Row key={misconception.fields.pl+"/"+misconception.fields.name}>
              <Table.Cell style={{backgroundColor: bgcolor(mi)}}>
                <Link to={`/misconceptions/${misconception.fields.pl}/${misconception.fields.name}/`}>
                  {misconception.fields.name}
                </Link>
              </Table.Cell>
              <Table.Cell style={{backgroundColor: bgcolor(mi)}}>
                {misconception.fields.pl}
              </Table.Cell>
              <Table.Cell style={{backgroundColor: bgcolor(mi)}}>
                { misconception.frontmatter.status === 'draft' 
                ? <span style={{color: '#e0e0e0'}}>draft</span>
                : misconception.frontmatter.status
                }
              </Table.Cell>
              <Table.Cell style={{backgroundColor: bgcolor(mi)}}>
                { misconception.frontmatter.references 
                ? (
                    <>
                      <List>
                        {misconception.frontmatter.references.map(reference => {
                          const refEntry = args.data.refs.edges.filter(edge => edge.node.slug === "references/"+reference.citationKey);
                          return (
                            <List.Item
                                key={`zotero-${reference.citationKey}`}
                              >
                                <List.Icon className="file alternate outline" size='large' verticalAlign='middle' />
                                <List.Content>
                                  <List.Header>
                                    <Link to={`/reference/${reference.citationKey}`}>
                                      {reference.citationKey}
                                    </Link>
                                  </List.Header>
                                  <strong>{reference.id}:</strong> {reference.name}
                                  {
                                    reference.relationship ? <> ({reference.relationship})</> : null
                                  }
                                  {
                                    refEntry.map((edge) => (
                                      <>
                                        <br/><em>{edge.node.frontmatter.method}</em>
                                        <br/>{edge.node.frontmatter.subjects}<br/>

                                        {
                                          edge.node.frontmatter.langs.map(lang => (
                                            <Label
                                              key={lang}
                                              color="red"
                                            >
                                            { lang }
                                            </Label>
                                          ))
                                        }
                                        {
                                          edge.node.frontmatter.artifact ? <span style={{ marginLeft: "1rem" }} data-tooltip="Artifact available" ><Icon className="brown archive" size="large" /></span> : null
                                        }
                                      </>
                                    ))
                                  }
                                  <br/>
                                </List.Content>
                              </List.Item>
                          );
                        }
                        )}
                      </List>
                    </>

                  )
                : null
                }
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  </Layout>;
}

export default ReferencesOverviewPage

export const pageQuery = graphql`
  query {
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          references {
            citationKey
            id
            name
            relationship
          }
        }
      }
    }

    refs: allMdx(filter: {frontmatter: {type: {eq: "ref"}}}) {
      edges {
        node {
          id
          slug
          frontmatter {
            langs
            method
            subjects
            artifact
          }
          fields {
            slug
            name
            pl
          }
        }
      }
    }
  }
`
